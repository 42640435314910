import request from '@/common/utils/request'
import Vue from "vue";



export function genggai(data) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/participate/change-classify',
    method: 'post',
    data
  })
}

