<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >刷新</el-button
              >
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.username"
                size="mini"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <!--
              <el-form-item label="">
                <el-select v-model="searchForm.userTypeId" size="mini" placeholder="请选择嘉宾类型">
                  <el-option v-for="item in userTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              -->
          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-select
                v-model="searchForm.xingchengZt"
                size="mini"
                placeholder="请选择行程状态"
              >
                <el-option
                  v-for="item in xingchengZtList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="showForm"
                >更多条件</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>
          </el-form>

          <!-- 导出Excel -->
          <el-form :inline="true" size="medium" :model="searchForm"> </el-form>
          <el-form
            v-show="searchVisible == -1"
            :inline="true"
            class="invisibleForm"
          >
            <el-form-item label="">
              <el-select
                v-model="searchForm.yyCompanyId"
                size="mini"
                placeholder="请选择邀宾单位/部门"
              >
                <el-option
                  v-for="item in yyCompanyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.createUserId"
                size="mini"
                placeholder="请选择管理员"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-select
                v-model="searchForm.status"
                size="mini"
                placeholder="请选择参会状态"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.hotelId"
                size="mini"
                placeholder="请选择酒店"
              >
                <el-option
                  v-for="item in hotelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.qy"
                size="mini"
                placeholder="请选择付费方式"
              >
                <el-option
                  v-for="item in qyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-input
                v-model="searchForm.phone"
                size="mini"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.post"
                size="mini"
                placeholder="请输入职务职级"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.company"
                size="mini"
                placeholder="请输入单位"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.workGroup"
                size="mini"
                placeholder="请选择部门/组"
              >
                <el-option
                  v-for="item in workGroupList"
                  :key="item.value"
                  :label="item.title"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.country"
                size="mini"
                placeholder="请选择国家"
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.handlerId"
                size="mini"
                placeholder="请选择联络员"
              >
                <el-option
                  v-for="item in handlerUserList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :cell-style="columnStyle"
      :data="tableData"
      ref="elTable"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      border
    >
      <el-table-column
        highlight-current-row
        type="selection"
        align="center"
        width="55"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="sortBy"
        label="排序"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button
            slot="reference"
            type="text"
            size="small"
            style="color: #606266"
            @click="showEditSortBy(scope.row, scope.$index)"
            >{{ scope.row.sortBy }}</el-button
          >
          <!--          <el-popover-->
          <!--            placement="top-start"-->
          <!--            trigger="manual"-->
          <!--            v-model="editSortBy.v['v'+scope.$index]"-->
          <!--            width="160">-->
          <!--            <el-input v-model="editSortBy.sortByEditValue" size="mini" placeholder="请填写排序"></el-input>-->
          <!--            <div style="text-align: right; margin: 0px; margin-top: 10px;">-->
          <!--              <el-button type="primary" size="mini" @click="updateSortBy(scope.row, scope.$index)">确定</el-button>-->
          <!--            </div>-->
          <!--            <el-button slot="reference" type="text" size="small" style="color: #606266" @click="showEditSortBy(scope.row, scope.$index)">{{ scope.row.sortBy }}</el-button>-->
          <!--          </el-popover>-->
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="username"
        label="姓名"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="postRank"
        label="职级"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="company"
        label="单位"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="post"
        label="职务"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="userTypeName"
        label="嘉宾类型"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="yyCompanyName"
        :label="utid == 10 ? '所属部门' : '邀约单位'"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>
          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">已删除</template>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="note2"
        label="嘉宾活动安排备注"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="note"
        label="嘉宾个人需求备注"
        show-overflow-tooltip
      >
      </el-table-column>
      <template
        v-if="
          utid == 1 ||
          utid == 2 ||
          utid == 3 ||
          utid == 4 ||
          utid == 5 ||
          utid == 6
        "
      >
        <el-table-column
          align="center"
          prop="xingchengZt"
          label="当前行程状态"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="hotelName"
          label="酒店"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <!-- <el-table-column
        align="center"
        prop="yq"
        label=""
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.yq == 0">7天内未去过中高风险地区</template>
          <template v-else-if="scope.row.yq == 1"
            >7天内去过中高风险地区</template
          >
        </template>
      </el-table-column> -->
      <template
        v-if="
          utid == 1 ||
          utid == 2 ||
          utid == 3 ||
          utid == 4 ||
          utid == 5 ||
          utid == 6
        "
      >
        <el-table-column
          align="center"
          prop="arriveTime"
          label="抵青时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ fmtDate2(scope.row.arriveTime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveMode"
          label="抵达方式"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="frequency"
          label="抵达航班/车次信息"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="carnum"
          label="抵达车牌"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveStation"
          label="接站口"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="arriveTime"
          label="离青时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ fmtDate2(scope.row.backTime) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="backMode"
          label="抵达方式"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="backFrequency"
          label="抵达航班/车次信息"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="backCarnum"
          label="抵达车牌"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="lkdd"
          label="送站口"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>

      <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ fmtDate(new Date(scope.row.createTime * 1000)) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createUserName"
        label="创建人"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateTime"
        label="最新修改时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ fmtDate(new Date(scope.row.updateTime * 1000)) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateUserName"
        label="最新修改人"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        align="center"
        prop="address"
        label="操作"
        min-width="180"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="editLeixing(scope.row)"
            >修改嘉宾类型</el-button
          >
          <!-- <el-button
            size="mini"
            type="success"
            @click="submitSH(scope.$index, scope.row)"
            v-if="scope.row.status == 3 || scope.row.status == 0"
            >审核</el-button
          > -->
          <!--          <el-button size="mini" type="success" @click="submitSH(scope.$index, scope.row)" v-if="scope.row.status==0||scope.row.status==2||scope.row.status==3">审核</el-button>-->

          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="searchForm.pageSize"
          :page-sizes="[10, 20, 50]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <el-dialog
      :title="editForm.id == 0 ? '新增' + utname : '修改' + utname"
      :visible.sync="editFormVisible"
      width="80%"
      top="1vh"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm" :rules="editRules">
        <!--
          <el-form-item label="嘉宾类型" label-width="120px" prop="userTypeId">
            <el-radio v-for="(item,index) in userTypeList" v-model="editForm.userTypeId" :label="item.id" v-if="index>0" @change="onUserTypeChange">{{ item.name }}</el-radio>
          </el-form-item>
          -->
        <el-form-item label="嘉宾类型" label-width="120px" prop="userTypeId">
          {{ utname }}
        </el-form-item>
        <el-row v-if="addShowFlag">
          <el-col :span="8">
            <el-form-item
              label="绑定用户"
              label-width="120px"
              prop="frontUserId"
            >
              <el-select
                v-model="editForm.frontUserId"
                size="small"
                clearable
                filterable
                placeholder="直接选择或搜索选择"
              >
                <el-option
                  v-for="item in frontUserList"
                  :key="item.id"
                  :label="item.nickname + '(' + item.phoneNum + ')'"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="绑定联络员"
              label-width="120px"
              prop="handlerId"
            >
              <el-select
                v-model="editForm.handlerId"
                size="small"
                clearable
                filterable
                placeholder="直接选择或搜索选择"
              >
                <el-option
                  v-for="(item, index) in userList"
                  :key="item.id"
                  :label="item.nickname + '(' + item.username + ')'"
                  :value="item.id"
                  v-if="index > 0"
                >
                </el-option>
              </el-select>
              <!--              <el-input size="small" v-model="editForm.handlerId" autocomplete="off" placeholder="直接选择或搜索选择"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row v-if="utid == 6">
          <el-col :span="8">
            <el-form-item label="贵宾" label-width="120px" prop="ppid">
              <el-select
                size="small"
                v-model="editForm.ppid"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in gbList"
                  :key="item.id"
                  :label="item.username"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-form-item label="是否排序" label-width="120px">
            <el-switch
              v-model="paixu"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="排序" v-if="paixu == true" label-width="120px">
            <el-input v-model="editForm.sortBy" placeholder="请输入排序" />
          </el-form-item>
          <el-col :span="8">
            <el-form-item label="姓名" label-width="120px" prop="username">
              <el-input
                size="small"
                v-model="editForm.username"
                autocomplete="off"
                placeholder="请输入参会人员姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" label-width="120px" prop="gender">
              <el-radio-group size="small" v-model="editForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系方式" label-width="120px" prop="phone">
              <el-input
                size="small"
                v-model="editForm.phone"
                autocomplete="off"
                placeholder="请输入参会人联系方式"
                maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="联系电话类型"
              label-width="120px"
              prop="phoneType"
            >
              <el-select
                v-model="editForm.phoneType"
                size="small"
                autocomplete="off"
                filterable
                allow-create
                default-first-option
                placeholder="选择联系电话类型"
              >
                <el-option key="本人" label="本人" value="本人"></el-option>
                <el-option key="助理" label="助理" value="助理"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱" label-width="120px" prop="email">
              <el-input
                size="small"
                v-model="editForm.email"
                autocomplete="off"
                placeholder="请输入参会人邮箱"
                maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位" label-width="120px" prop="company">
              <el-input
                size="small"
                v-model="editForm.company"
                autocomplete="off"
                placeholder="请输入参会人员单位"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职务" label-width="120px" prop="handlerId">
              <el-input
                size="small"
                v-model="editForm.post"
                autocomplete="off"
                placeholder="请输入参会人员职务"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职级" label-width="120px" prop="postRank">
              <el-input
                size="small"
                v-model="editForm.postRank"
                autocomplete="off"
                placeholder="请输入参会人员职级"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="editForm.userTypeId == 1 || editForm.userTypeId == 2">
          <el-col :span="8">
            <el-form-item label="迎送领导" label-width="120px" prop="yslingdao">
              <el-input
                size="small"
                v-model="editForm.yslingdao"
                autocomplete="off"
                placeholder="如需,请填入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="迎送备注" label-width="120px" prop="ysdesc">
              <el-input
                size="small"
                v-model="editForm.ysdesc"
                autocomplete="off"
                placeholder="如需,请填入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="国籍" label-width="120px" prop="country">
              <el-input
                size="small"
                v-model="editForm.country"
                autocomplete="off"
                placeholder="国籍"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="证件号码" label-width="120px" prop="idcard">
              <el-input
                size="small"
                v-model="editForm.idcard"
                autocomplete="off"
                placeholder="请输入证件号码,身份证或护照"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="证件" label-width="120px" prop="idCardType">
              <el-radio-group size="small" v-model="editForm.idCardType">
                <el-radio :label="0">身份证</el-radio>
                <el-radio :label="1">护照</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="地址" label-width="120px" prop="sheng">
              <el-cascader
                size="small"
                clearable
                class="elCacader"
                v-model="selectValue"
                :props="defultProp"
                :options="address"
                @change="handleAddressChange"
              ></el-cascader>
              {{ selectValue }}
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          label="邀请参会"
          label-width="120px"
          prop="hyIds"
          v-if="editForm.userTypeId != 8"
        >
          <el-tabs v-model="tabsActiveName" type="border-card">
            <el-tab-pane
              v-for="(item, index) in plateWithGroupList"
              :label="item.title"
              :name="item.title"
              :key="index"
            >
              <el-checkbox-group
                v-model="editForm.hyIds"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="(item2, index2) in item.plateList"
                  :label="item2.id"
                  :key="index2"
                  name="type"
                  >{{ item2.title }}</el-checkbox
                >
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item
          label="邀请参会"
          label-width="120px"
          prop="hyIds"
          v-if="editForm.userTypeId == 8"
        >
          <el-tabs v-model="zyTabsActiveName" type="border-card">
            <el-tab-pane
              v-for="(item, index) in zyPlateList"
              :label="item.title"
              :name="item.title"
              :key="index"
            >
              <el-checkbox-group
                v-model="editForm.hyIds"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox
                  v-for="(item2, index2) in item.plateList"
                  :label="item2.id"
                  :key="index2"
                  name="type"
                  >{{ item2.title }}</el-checkbox
                >
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item
          label="邀约单位/部门"
          label-width="120px"
          prop="yyCompanyId"
        >
          <el-radio
            v-for="(item, index) in yyCompanyList"
            v-model="editForm.yyCompanyId"
            :label="item.id"
            v-if="index > 0"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
        <el-form-item
          label="部门组"
          label-width="120px"
          prop="workGroup"
          v-if="utid == 10"
        >
          <el-radio
            v-for="(item, index) in workGroupList"
            v-model="editForm.workGroup"
            :label="item.title"
            v-if="index > 0"
            >{{ item.title }}</el-radio
          >
        </el-form-item>
        <el-form-item
          label="付费方式"
          label-width="120px"
          prop="qy"
          v-if="
            utid == 1 ||
            utid == 2 ||
            utid == 3 ||
            utid == 4 ||
            utid == 5 ||
            utid == 6
          "
        >
          <el-radio
            v-for="(item, index) in qyList"
            v-model="editForm.qy"
            :label="item.value"
            v-if="index > 0"
            >{{ item.label }}</el-radio
          >
          <el-input
            size="small"
            v-model="editForm.qyOther"
            autocomplete="off"
            placeholder="选择其他,请填写"
            style="width: 300px"
          ></el-input>
          <div style="color: red">
            部分嘉宾费用由承办方全部承担，原则上青岛市内嘉宾不承担住宿费。
          </div>
        </el-form-item>
        <el-form-item label="嘉宾活动安排备注" label-width="130px" prop="note2">
          <el-input
            size="small"
            v-model="editForm.note2"
            autocomplete="off"
            placeholder="填写嘉宾是否有签约，座谈、颁奖、主持等活动"
          ></el-input>
        </el-form-item>
        <el-form-item label="嘉宾个人需求备注" label-width="130px" prop="note">
          <el-input
            size="small"
            v-model="editForm.note"
            autocomplete="off"
            placeholder="请输入备注信息,如宗教信仰民族等"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否参会" label-width="120px" prop="status">
          <el-radio v-model="editForm.status" :label="2">待定</el-radio>
          <el-radio v-model="editForm.status" :label="3">确认参加</el-radio>
        </el-form-item>
        <!-- <el-form-item label="疫情登记" label-width="120px" prop="yq">
          <el-radio v-model="editForm.yq" :label="0"
            >7天内未去过中高风险地区</el-radio
          >
          <el-radio v-model="editForm.yq" :label="1"
            >7天内去过中高风险地区</el-radio
          >
        </el-form-item> -->

        <!--        <el-form-item label="邀约类型" label-width="120px" prop="yyType">-->
        <!--          <el-radio v-model="editForm.yyType" :label="1">正常嘉宾</el-radio>-->
        <!--          <el-radio v-model="editForm.yyType" :label="2">国家广电总局邀约</el-radio>-->
        <!--          <el-radio v-model="editForm.yyType" :label="3">山东省广电局邀约</el-radio>-->
        <!--          <el-radio v-model="editForm.yyType" :label="4">媒体记者</el-radio>-->
        <!--          <el-radio v-model="editForm.yyType" :label="5">工作人员</el-radio>-->
        <!--          <el-radio v-model="editForm.yyType" :label="6">观众</el-radio>-->
        <!--        </el-form-item>-->
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click.native.prevent="submitForm"
          :loading="loading"
          >{{ editForm.id == 0 ? "添 加" : "修 改" }}</el-button
        >
      </div>
    </el-dialog>

    <!-- <bmgl ref="bmgl" @fetch-data="getDataList(currentPage)" /> -->
    <edit ref="edit" @fetch-data="getDataList()" />
    <!-- <editbm ref="editbm" @fetch-data="getDataList(currentPage)" />
      <queren ref="queren" @fetch-data="getDataList" /> -->
  </div>
</template>
  
  <script>
// import queren from "../modeltwo/jiabintwo.vue"; //确认参加

// import bmgl from "../model/addxc.vue";
import edit from "./model/editleixing.vue";
// import editbm from "../model/editbmtwo.vue";

import { validPhone } from "@/common/utils/validate.js";
import request from "@/common/utils/request.js";
import util from "@/common/utils/util.js";
import { getUserTypeList } from "@/api/user_type";
import { getYycompanyList } from "@/api/yy_company";
import { getFrontUserList } from "@/api/front_user";

import {
  getxiaochengxuParticipateList,
  addParticipate,
  updateParticipate,
  deleteParticipate,
  updateDLInfo,
  updateSortBy,
  shenhe,
  getParticipateGB,
} from "@/api/participate";
import { getPlateList, getPlateListWithGroup } from "@/api/plate";
import { getSystemSet } from "@/api/system_setting";
import { getHotelList } from "@/api/hotel";
import { getAllUserList } from "@/api/user";
import json from "@/assets/address.json";
import axios from "axios";
import { getToken } from "@/common/utils/auth";

export default {
  name: "participateManage2",
  components: { edit },
  data() {
    return {
      didashijiantwo: "",
      options12: [],
      getfeijione: "",
      gaotiedaodaone: "", //高铁滴答滴点
      options11: [], //选择抵达方式高铁的地点
      gaotieone: "",
      gaotietwo: "",
      getfeijitwo: "",
      shifadi: false,
      formInline: {
        // user: "",
        // region: "",
        // 火车
        trainNum: "", //或测班次号
        date: "",
      },
      feijididadi: "",
      livetime: "",
      leftshijian: "",
      currentFactor: "",
      paixu: false,
      options9: [],
      options10: [],
      total: 0,
      options2: [],
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 8.64e7 || //不能选择之前的日期
            time.getTime() > Date.now() + 1000 * 3600 * 24 * 14 //这是校验不能选择超过15天的日期
          );
        },
      },
      editForm: {
        id: 0,
        frontUserId: "",
        handlerId: "",
        username: "",
        phone: "",
        phoneType: "",
        email: "",
        gender: "",
        company: "",
        yslingdao: "",
        ysdesc: "",
        country: "中国",
        idcard: "",
        idCardType: 0,
        sheng: "",
        shi: "",
        xian: "",
        hyIds: [],
        hyId: "",
        userTypeId: [],
        yyCompanyId: "",
        workGroup: "",
        qy: "",
        note: "",
        status: "",
        yq: "",
        yyType: "",
        faceImg: "",
        ppid: "",
        qyOther: "",
        note2: "",
      },
      gbList: [],
      editRules: {
        username: [
          { required: true, message: "请输入参会人员姓名", trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        company: [
          { required: true, message: "请输入参会人员单位", trigger: "blur" },
        ],
        post: [
          { required: true, message: "请输入参会人员职务", trigger: "blur" },
        ],
        // country: [{ required: true, message: "请输入国籍", trigger: "blur" }],
        // sheng: [{ required: true, message: "请选择地址", trigger: "blur" }],
        // hyIds: [
        //   { required: true, message: "请选择邀请参加会议", trigger: "blur" },
        // ],
        userTypeId: [
          { required: true, message: "请选择嘉宾类型", trigger: "blur" },
        ],
        qy: [{ required: true, message: "请选择付费方式", trigger: "blur" }],
        status: [
          { required: true, message: "请选择是否参会", trigger: "blur" },
        ],
        yq: [
          {
            required: true,
            message: "请选择7天内是否到过疫情地区",
            trigger: "blur",
          },
        ],
        yyType: [
          { required: true, message: "请选择邀约类型", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: validPhone,
            message: "手机号码格式错误",
            trigger: "blur",
          },
        ],
        email: [
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur"] },
        ],
      },
      loadingDiv: null,
      searchForm: {
        pageSize: 10,
        pageNum: 1,
        // cardId: "",
        // phone: "",
        // username: "",
        // post: "",
        userTypeId: [0],
        // company: "",
        // hyId: "",
        // status: "",
        // yyCompanyId: "",
        // workGroup: "",
        // hotelId: "",
        // country: "",
        // qy: "",
        // handlerId: "",
        // createUserId: "",
        // xingchengZt: "",
        // arriveTime1: "",
        // arriveTime2: "",
        // backTime1: "",
        // backTime2: "",
        // updateTime1: "",
        // updateTime2: "",
        // createTime1: "",
        // createTime2: "",
        // userTypeIdList: [],
      },

      date: {
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      plateList: [],
      statusList: [
        // 状态  -1取消  1通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾
        { value: "", label: "请选择参会状态" },
        { value: 0, label: "审核中" },
        { value: 1, label: "审核通过" },
        { value: 3, label: "确定参加" },
        { value: 2, label: "暂不确定" },
        { value: -1, label: "取消参加" },
        { value: -2, label: "嘉宾拒绝" },
      ],
      yyCompanyList: [],
      workGroupList: [],
      hotelList: [],
      countryList: [
        { value: "", label: "请选择国家" },
        { value: "中国", label: "中国" },
        { value: "非中国", label: "非中国" },
      ],
      qyList: [
        { value: "", label: "请选择付费方式" },
        { value: "全部承担", label: "全部承担" },
        { value: "承担住宿餐饮", label: "承担住宿餐饮" },
        { value: "仅餐饮", label: "仅餐饮" },
        { value: "全自费", label: "全自费" },
        { value: "其他", label: "其他" },
        // {value: '食宿补差', label: '食宿补差'},
        // {value: '住宿补差', label: '住宿补差'},
      ],
      handlerUserList: [],
      userList: [],
      xingchengZtList: [
        { value: "", label: "请选择行程状态" },
        { value: "未到", label: "未到" },
        { value: "已接到", label: "已接到" },
        { value: "到星光岛", label: "到星光岛" },
        { value: "入住酒店", label: "入住酒店" },
        { value: "已入场", label: "已入场" },
        { value: "离店", label: "离店" },
        { value: "离青", label: "离青" },
      ],
      userTypeList: [],
      address: [],
      selectValue: "",
      defultProp: {
        label: "name",
        value: "name",
        children: "sub",
      },
      isIndeterminate: false,
      checkAll: false,
      frontUserList: [],

      genPoster: false,
      yyhDialogVisible: false,
      genImgTitle: "",
      yyhImg: "",
      imgQrcodeContent: "",
      imgUserName: "",
      genYYHRow: null,
      painting: {
        width: `1080px`,
        height: `1890px`,
        background: "#f4f5f7",
        views: {},
      },
      // 日期组件内的值- 默认为当天时间
      timeValue: {
        stockDate: this.getNowTime(),
        stockDatetwo: this.getNowTime(),
      },
      getcheci: {}, //获取高铁火车班次
      arravivetwo: "",
      yyInfoRow: "",
      yyInfoCode: "",
      XCXScanTime: "",
      H5ScanTime: "",
      yyInfoSMS: "",
      yyInfoSendStatus: "",
      plateWithGroupList: [],
      searchVisible: 1,
      didashijian: "", //高铁抵达时间显示

      Height: 60 + "px",
      editDLFormVisible: false,
      editDLLoading: false,
      chufadi: false,
      editDLForm: {
        id: "",
        pid: "",
        username: "",
        phone: "",
        gender: "",

        esArriveTime: "", // 预计抵青时间
        arriveTime: "", // 抵青时间

        arriveMode: "", // 到达方式 '飞机', '高铁', '自驾'
        carnum: "", // 车牌号

        frequency: "", // 班次
        arriveStation: "", // 抵青接站口

        backTime: "", // 离青时间

        backMode: "", // 离青方式
        backCarnum: "", // 离青车牌号

        backFrequency: "", // 离青班次
        lkdd: "", // 离青送站口

        dqqy: "", // 当前权益

        hotelId: "", // 分配酒店
        eatHotelId: "", // 分配吃饭地点

        jdNote: "", // 酒店床位备注

        xingchengZt: "", // 行程状态
        xingchengZtCurrent: "", // 修改之前行程状态

        dlNote: "", // 抵离备注
      },
      editDLRules: {},
      addShowFlag: false,
      options1: [],
      getcheliveci: {}, //获取likai高铁火车班次
      dlDate: {
        date1: "",
        date2: "",
        date3: "",
      },
      jzkList: [
        // 接/送站口
        { label: "胶东国际机场", value: "胶东国际机场" },
        { label: "青岛北站", value: "青岛北站" },
        { label: "青岛站", value: "青岛站" },
        { label: "青岛西站", value: "青岛西站" },
      ],
      options3: [],
      options4: [],
      editSortBy: {
        sortByEditValue: "",
        pid: "",
      },
      zhuangtai: "",
      checkTime: "",
      leixing: "",
      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },

      baseUrl: "",
      uploadActionUrl: "",
      avatarUploadLoadingDiv: "",

      tabsActiveName: "",

      utid: -1,
      utname: "",

      zyPlateList: [],
      zyTabsActiveName: "",
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    // for(let i=0;i<100;i++){
    //   this.editSortBy.v["v"+i] = true;
    // }
    let utid = 0;
    this.utid = parseInt(utid);
    this.searchForm.userTypeId = [this.utid];
    // this.searchForm.userTypeIdList = [this.utid];
    this.editForm.userTypeId = this.utid;

    // 如果非 1~6 qy没有
    if (
      this.utid == 1 ||
      this.utid == 2 ||
      this.utid == 3 ||
      this.utid == 4 ||
      this.utid == 5 ||
      this.utid == 6
    ) {
    } else {
      delete this.editRules.qy;
    }

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };

    this.address = json;

    this.init();

    let t = getToken();
    this.excelImp.action =
      this.$adminApiUrl +
      "/participate/excel-imp?userType=" +
      this.utid +
      "&_t=" +
      t;
    this.baseUrl = this.$baseUrl;
    this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
  },
  methods: {
    queren(row, status) {
      this.$refs["queren"].showEdit(row, status);
    },
    handlebumen(row) {
      if (row.id) {
        this.$refs["editbm"].showEdit(row);
      } else {
        this.$refs["editbm"].showEdit();
      }
    },
    editLeixing(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    chosedidaone(e) {
      const nowTime =
        this.timeValue.stockDate + " " + e.arrive_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = thisTime;
      this.didashijian = nowTime;
    },
    didafeijishijian(e) {
      console.log(e);
      const nowTime = e.END_DATE + " " + e.END_TIME + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = e.ACTUAL_END_TIME;
      this.didashijiantwo = e.END_DATE + " " + e.END_TIME + ":" + "00";
    },
    chooseall(e) {
      //飞机的离青时间
      const nowTime = e.START_DATE + " " + e.START_TIME + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
      this.getfeijitwo = nowTime;
    },
    onSubmithree() {
      this.options3 = [];
      this.options4 = [];
      let that = this;
      if (!that.editDLForm.backFrequency) {
        this.$alert("请选择航班班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else if (!that.leftshijian) {
        this.$alert("请选择航班时间", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else {
        axios
          .get(this.$baseUrl + "/adminapi/plain-select", {
            params: {
              date: that.leftshijian,
              backFrequency: that.editDLForm.backFrequency,
            },
          })
          .then((res) => {
            this.chufadi = true;
            this.options3 = res.data.data;
            this.options4 = res.data.data;
            console.log(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onSubmitfour() {
      let that = this;
      this.options9 = [];
      this.options10 = [];
      if (!that.editDLForm.backFrequency) {
        this.$alert("请选择高铁班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
      } else {
        axios
          .get("https://ybhcms.gengduoke.com/otn/queryTrainInfo/query", {
            // https://ybhcms.gengduoke.com/otn/queryTrainInfo/query
            //在宝塔配置反向代理，ybhcms.gengduoke.com是域名，queryTrainInfo/query是请求的路径，otn是在vue。config。js配置的请求地址
            //https://kyfw.12306.cn/otn/queryTrainInfo/query
            params: {
              "leftTicketDTO.train_no": that.getcheliveci.train_no,
              "leftTicketDTO.train_date": that.livetime,
              rand_code: "",
            },
          })
          .then((res) => {
            this.options9 = res.data.data.data;
            this.options10 = res.data.data.data;
            this.chufadi = true;
            console.log(res.data.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleSelectwo(item) {
      this.editDLForm.backFrequency = item.station_train_code;
      this.getcheliveci = item;
    },
    querySearchAsync(queryString, cb) {
      //高铁的离开方式获取车次
      let originalString = this.livetime;
      let reg = new RegExp("-", "g"); //去除字符串中所有的-
      let datas = originalString.replace(reg, "");

      axios
        .get("https://search.12306.cn/search/v1/train/search", {
          params: {
            keyword: queryString,
            date: datas,
          },
        })
        .then((res) => {
          let nameAndId = res.data.data;
          cb(nameAndId);
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    choosetwo() {
      var str = this.leftshijian.date;
      var a1 = str.indexOf(" ");
      var a2 = str.substring(0, a1); //字符串截取

      //飞机抵达时间

      const nowTime = a2 + " " + this.arravivetwo + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = thisTime;
    }, //选择抵达时间
    onSubmitwo() {
      this.options1 = [];
      this.options2 = [];

      this.options12 = [];
      let that = this;
      if (!that.editDLForm.frequency) {
        this.$alert("请选择航班班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else if (!that.searchForm.date) {
        this.$alert("请选择航班时间", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
        return;
      } else {
        axios
          .get(this.$baseUrl + "/adminapi/plain-select", {
            params: {
              date: that.searchForm.date,
              frequency: that.editDLForm.frequency,
            },
          })
          .then((res) => {
            this.shifadi = true;
            this.options1 = res.data.data;
            this.options2 = res.data.data;
            console.log(res.data.data);
            this.options12 = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //新增查询同步
    querySearch(queryString, callback) {
      // let JsonData = {
      //   validBegNm: "2022-06-02",
      // };
      // JsonData.validBegNm.replace("-", "");
      // console.log(JsonData.validBegNm, "测试是否去除字符");
      // 结果： 20220602

      let originalString = this.timeValue.stockDate;
      let reg = new RegExp("-", "g"); //去除字符串中所有的-
      let datas = originalString.replace(reg, "");

      axios
        .get("https://search.12306.cn/search/v1/train/search", {
          params: {
            keyword: queryString,
            date: datas,
          },
        })
        .then((res) => {
          let nameAndId = res.data.data;
          callback(nameAndId);
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      // axios({
      //   method: "get",
      //   url: "https://search.12306.cn/search/v1/train/search", // 请求地址
      //   data: {
      //     keyword: queryString,
      //     date: this.value2,
      //   }, // 参数
      //   // headers: headers,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    getNowTime() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
      return defaultDate;
    }, //获取当前时间
    onSubmit() {
      this.options1 = [];
      this.options2 = [];
      this.options11 = [];
      let that = this;
      if (!that.editDLForm.frequency) {
        this.$alert("请选择高铁班次号", {
          dangerouslyUseHTMLString: true,
          type: "warning",
          showClose: false,
        });
      } else {
        axios
          .get("https://ybhcms.gengduoke.com/otn/queryTrainInfo/query", {
            // https://ybhcms.gengduoke.com/otn/queryTrainInfo/query
            //在宝塔配置反向代理，ybhcms.gengduoke.com是域名，queryTrainInfo/query是请求的路径，otn是在vue。config。js配置的请求地址
            //https://kyfw.12306.cn/otn/queryTrainInfo/query
            params: {
              "leftTicketDTO.train_no": that.getcheci.train_no,
              "leftTicketDTO.train_date": that.timeValue.stockDate,
              rand_code: "",
            },
          })
          .then((res) => {
            this.options1 = res.data.data.data;
            this.options2 = res.data.data.data;
            this.options11 = res.data.data.data;
            this.shifadi = true;
            console.log(res.data.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleSelect(item) {
      console.log(this.timeValue.stockDate);
      this.editDLForm.frequency = item.station_train_code;
      this.getcheci = item;
    },
    handlexingcheng(row) {
      if (row.id) {
        this.$refs["bmgl"].showEdit(row);
      } else {
        this.$refs["bmgl"].showEdit();
      }
    },
    chooseone(e) {
      const nowTime =
        this.timeValue.stockDate + " " + e.start_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = thisTime;
      this.gaotieone = thisTime;
    }, //选择抵达时间

    showForm() {
      if (this.searchVisible == 1) {
        this.Height = 120 + "px";
        this.searchVisible = -1;
      } else {
        this.Height = 60 + "px";
        this.searchVisible = 1;
      }
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
        return "background:	#FFF8DC;";
      }
    },
    xuanzhong(e) {
      console.log(e.id);
      console.log(this.currentFactor.id);
    },
    exportExcelgeren(e) {
      if (e == undefined) {
        this.$confirm("请选择导出项");
      } else {
        const loadingDiv = util.showLoading("正在导出EXCEL");
        let t = getToken();
        let headers = { "x-token": t };
        axios({
          method: "post",
          url: this.$adminApiUrl + "/participate/export-excel-aboutone", // 请求地址
          data: {
            id: e,
          }, // 参数
          headers: headers,
          responseType: "blob", // 表明返回服务器返回的数据类型
        })
          .then((res) => {
            loadingDiv.close();
            let blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");

            let fileName = "个人行程表";

            link.download = fileName + "_" + timeStr + ".xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getParticipateGB() {
      getParticipateGB({ my: true })
        .then((res) => {
          if (res && res.code == 1) {
            console.log(res.data);
            this.gbList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onUserTypeChange(e) {
      if (e == 1 || e == 2) {
        this.editForm.qy = "仅餐饮";
      }
      if (e == 3 || e == 4) {
        this.editForm.qy = "全部承担";
      }

      if (e == 8 || this.userTypeIdBak == 8) {
        this.editForm.hyIds = [];
      }

      if (e == 5) {
        this.editForm.hyIds = [1];
      }

      this.userTypeIdBak = e;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.avatarUploadLoadingDiv.close();
      if (res.code == 1) {
        // this.$message({
        //   message: '文件上传成功',
        //   type: 'success'
        // });
        this.editForm.faceImg = res.data;
        return;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    beforeAvatarUpload() {
      this.avatarUploadLoadingDiv = util.showLoading("照片上传中");
    },
    handleAvatarError() {
      this.avatarUploadLoadingDiv.close();
      this.$message({
        message: "照片上传失败,请检查网络",
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.getDataList(1);
          this.excelImp.dialogVisible = false;
        });
      }
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    submitSH(index, row) {
      this.$confirm("嘉宾姓名: [" + row.username + "] ,确认审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loadingDiv = util.showLoading("设置审核通过中");
          shenhe({ pid: row.id, id: row.id, status: 1 })
            .then((res) => {
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.getDataList(this.currentPage);
                });
              }
              loadingDiv.close();
            })
            .catch((res) => {
              loadingDiv.close();
            });
        })
        .catch(() => {});
    },
    showEditSortBy(row, index) {
      const loadingDiv = util.showLoading("修改排序中...");
      this.editSortBy.pid = row.id;
      this.editSortBy.sortByEditValue = row.sortBy;
      this.$prompt("请输入排序", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: "请输入正整数",
        inputValue: row.sortBy,
        closeOnClickModal: false,
      })
        .then(({ value }) => {
          updateSortBy({
            pid: this.editSortBy.pid,
            id: this.editSortBy.pid,
            sortBy: value,
          })
            .then((res) => {
              loadingDiv.close();
              if (res.code == 0) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then((res) => {
                  this.getDataList(this.currentPage);
                });
              }
            })
            .catch(() => {
              loadingDiv.close();
              this.$alert("修改失败,请检查您的网络", {
                dangerouslyUseHTMLString: true,
                type: "warning",
              });
            });
        })
        .catch(() => {
          loadingDiv.close();
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });
        });
    },
    closetwo() {
      this.editDLFormVisible = false;
      let row = {};
      this.feditDLFormorm = Object.assign({}, row);
    },
    submitEditDL() {
      console.log("修改抵离信息");
      console.log(this.editDLForm);
      const loadingDiv = util.showLoading("修改抵离信息中");

      if (util.isNotEmpty(this.dlDate.date1)) {
        this.editDLForm.esArriveTime = this.dlDate.date1 / 1000;
      }
      if (util.isNotEmpty(this.dlDate.date2)) {
        this.editDLForm.arriveTime = this.dlDate.date2 / 100;
      }
      if (util.isNotEmpty(this.dlDate.date3)) {
        this.editDLForm.backTime = this.dlDate.date2 / 1000;
      }

      updateDLInfo(this.editDLForm)
        .then((res) => {
          if (res.code == 0) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
          } else if (res.code == 1) {
            this.$alert(res.msg, {
              dangerouslyUseHTMLString: true,
              type: "success",
            }).then(() => {
              this.closetwo();
              this.getDataList(this.currentPage);
            });
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    choosepall(e) {
      console.log(e.START_TIME);
      //敌情飞机时间
      const nowTime = e.START_DATE + " " + e.START_TIME + ":" + "00";

      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date2 = thisTime;

      this.getfeijione = nowTime;
    },
    choosethree() {
      var str = this.leftshijian;
      var a1 = str.indexOf(" ");
      var a2 = str.substring(0, a1); //字符串截取

      //飞机离开时间

      const nowTime = a2 + " " + this.arravivetwo + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
    },
    choosetwoall(e) {
      //高铁的离青时间
      const nowTime = this.livetime + " " + e.start_time + ":" + "00";
      const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
      this.dlDate.date3 = thisTime;
      this.gaotietwo = nowTime;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D = date.getDate() + " ";
      let h = date.getHours() + ":";
      let m = date.getMinutes() + ":";
      let s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    handleEditDL(index, row) {
      this.editDLForm = row;
      this.editDLForm.frequency = row.frequency;
      console.log(row.backTime);
      this.didashijian = this.timestampToTime(row.arriveTime);
      this.didashijiantwo = this.timestampToTime(row.arriveTime);
      this.gaotietwo = this.timestampToTime(row.backTime);
      this.getfeijitwo = this.timestampToTime(row.backTime);
      this.editDLForm.pid = row.id;
      this.editDLForm.id = row.id;

      // 预计抵青时间
      this.editDLForm.esArriveTime = row.esArriveTime;
      if (util.isNotEmpty(row.esArriveTime)) {
        this.dlDate.date1 = row.esArriveTime * 1000;
      }

      // 抵青时间
      this.editDLForm.arriveTime = row.arriveTime;
      if (util.isNotEmpty(row.arriveTime)) {
        this.dlDate.date2 = row.arriveTime * 1000;
      }

      this.editDLForm.arriveMode = row.arriveMode; // 到达方式 '飞机', '高铁', '自驾'
      this.editDLForm.carnum = row.carnum; // 车牌号
      this.editDLForm.frequency = row.frequency; // 班次
      this.editDLForm.arriveStation = row.arriveStation; // 抵青接站口

      // 离青时间
      this.editDLForm.backTime = row.backTime;
      if (util.isNotEmpty(row.backTime)) {
        this.dlDate.date3 = row.backTime * 1000;
      }

      this.editDLForm.backMode = row.backMode; // 离青方式
      this.editDLForm.backCarnum = row.backCarnum; // 离青车牌号
      this.editDLForm.backFrequency = row.backFrequency; // 离青班次
      this.editDLForm.lkdd = row.lkdd; // 离青送站口
      this.editDLForm.dqqy = row.qy; // 权益
      this.editDLForm.hotelId = row.hotelId; // 分配酒店
      this.editDLForm.eatHotelId = row.eatHotelId; // 分配吃饭地点
      this.editDLForm.jdNote = row.jdNote; // 酒店床位备注
      this.editDLForm.xingchengZt = row.xingchengZt; // 行程状态
      this.editDLForm.xingchengZtCurrent = row.xingchengZt; // 修改之前行程状态
      this.editDLForm.dlNote = row.dlNote; // 抵离备注

      this.editDLFormVisible = true;
    },
    getXingchengZtDisabled(label) {
      if (util.isEmpty(this.editDLForm.xingchengZtCurrent)) {
        return false;
      }

      let labelIndex = -1;
      let currentIndex = -1;
      for (let i in this.xingchengZtList) {
        if (this.xingchengZtList[i].label == label) {
          labelIndex = i;
        }
        if (
          this.xingchengZtList[i].label == this.editDLForm.xingchengZtCurrent
        ) {
          currentIndex = i;
        }
      }

      if (labelIndex < currentIndex) {
        return true;
      }
      return false;
    },

    handleCheckAllChange(val) {
      if (val) {
        let ids = [];
        for (let i in this.plateList) {
          ids.push(this.plateList[i].id);
        }
        this.editForm.hyIds = ids;
      } else {
        this.editForm.hyIds = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.plateList.length - 1;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.plateList.length - 1;
    },
    handleAddressChange() {
      if (this.selectValue.length == 2) {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[0];
        this.editForm.xian = this.selectValue[1];
      } else {
        this.editForm.sheng = this.selectValue[0];
        this.editForm.shi = this.selectValue[1];
        this.editForm.xian = this.selectValue[2];
      }
    },
    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      for (let key in this.date) {
        this.date[key] = "";
      }
      this.searchForm.userTypeIdList = [this.utid];
      this.searchForm.userTypeId = [this.utid];

      this.getDataList(1);
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          // this.userTypeList = res.data;
          // this.userTypeList.unshift({name: "请选择嘉宾类型", id:''})
          let userTypeList = [];
          userTypeList.push({ name: "请选择嘉宾类型", id: "" });
          for (let i in res.data) {
            if (res.data[i].id == this.utid) {
              userTypeList.push(res.data[i]);
              this.utname = res.data[i].name;
            }
          }

          this.userTypeList = userTypeList;
        }
      });

      // getPlateList().then(res=>{
      //   if (res.code){
      //     this.plateList = res.data;
      //     this.plateList.unshift({title: "请选择会议", id:''})
      //   }
      // });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          if (this.utid != 10) {
            this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
          } else {
            this.yyCompanyList.unshift({ name: "请选择所属单位/部门", id: "" });
          }
        }
      });

      getSystemSet({ group: "work_group" }).then((res) => {
        if (res.code) {
          // this.yyCompanyList = res.data;
          this.workGroupList.unshift({ title: "请选择部门/组", value: "" });
          for (let i in res.data) {
            this.workGroupList.push({
              title: res.data[i].title,
              value: res.data[i].title,
            });
          }
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.handlerUserList = res.data;

          this.userList.unshift({ id: "", nickname: "请选择管理员" });
          this.handlerUserList.unshift({ id: "", nickname: "请选择联络员" });
        }
      });

      // 按照分组获取会议
      getPlateListWithGroup({ isZy: 0 }).then((res) => {
        this.plateWithGroupList = res.data;
        if (res.code) {
          this.tabsActiveName = res.data[0].title;
        }
      });

      // 按照分组获取展映
      getPlateListWithGroup({ isZy: 2 }).then((res) => {
        this.zyPlateList = res.data;
        if (res.code) {
          this.zyTabsActiveName = this.zyPlateList[0].title;
        }
      });
    },
    parsePlate(row, status) {
      if (util.isNotEmpty(row.plateList)) {
        console.log(1);
        let str = "";
        let index = 1;
        let plateList = row.plateList;
        for (let i in plateList) {
          if (util.isEmpty(plateList[i])) {
            continue;
          }

          str +=
            "<div class='ellipsis'>" +
            index +
            ".<" +
            plateList[i].title +
            "></div>";
          index++;
        }

        if (status == 1) {
          if (row.status == 1 || row.status == 3) {
            return "共" + plateList.length + "条";
          }
        } else if (status == 0) {
          if (row.status == 2) {
            return "共" + plateList.length + "条";
          }
        } else {
          return "共0条";
        }
      } else {
        return "共0条";
      }
    },
    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2) || d2 <= 0) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    showAddDialogForm() {
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }

      for (let key in this.editForm) {
        this.editForm[key] = "";
      }
      this.addShowFlag = false;
      this.editForm.id = 0;
      this.editForm.country = "中国";
      this.editForm.idCardType = 0;
      this.editForm.hyIds = [];
      this.selectValue = "";
      this.tabsActiveName = this.plateWithGroupList[0].title;
      this.editForm.userTypeId = this.utid;

      this.editFormVisible = true;
    },
    handleEdit(index, row) {
      this.getParticipateGB();
      if (this.$refs.editForm) {
        this.$refs.editForm.clearValidate();
      }

      if (this.frontUserList && this.frontUserList.length <= 0) {
        getFrontUserList().then((res) => {
          if (res.code) {
            this.frontUserList = res.data;
          }
        });
      }
      if (row.sortBy) {
        this.paixu = true;
        this.editForm.sortBy = row.sortBy;
      }
      this.addShowFlag = true;
      this.editForm.id = row.id;
      this.editForm.frontUserId = row.frontUserId;
      this.editForm.handlerId = row.handlerId;
      this.editForm.username = row.username;
      this.editForm.phone = row.phone;
      this.editForm.gender = row.gender;
      this.editForm.company = row.company;
      this.editForm.post = row.post;
      this.editForm.yslingdao = row.yslingdao;
      this.editForm.ysdesc = row.ysdesc;
      this.editForm.country = row.country;
      this.editForm.idcard = row.idcard;
      this.editForm.idCardType = row.idCardType;
      this.editForm.sheng = row.sheng;
      this.editForm.shi = row.shi;
      this.editForm.xian = row.xian;
      this.editForm.email = row.email;
      this.editForm.phoneType = row.phoneType;
      this.editForm.faceImg = row.faceImg;
      this.editForm.ppid = row.ppid;
      this.editForm.qyOther = row.qyOther;
      this.editForm.note2 = row.note2;

      if (row.sheng == row.shi) {
        this.selectValue = [row.sheng, row.xian];
      } else {
        this.selectValue = [row.sheng, row.shi, row.xian];
      }

      let hyIds = [];
      if (row.plateList && row.plateList.length > 0) {
        for (let i in row.plateList) {
          hyIds.push(row.plateList[i].plateId);
        }
      }
      this.editForm.hyIds = hyIds;
      this.editForm.userTypeId = row.userTypeId;
      this.editForm.yyCompanyId = row.yyCompanyId;
      this.editForm.workGroup = row.workGroup;
      this.editForm.qy = row.qy;
      this.editForm.note = row.note;
      this.editForm.status = row.status;
      this.editForm.yq = row.yq;
      this.editForm.yyType = row.yyType;
      this.tabsActiveName = this.plateWithGroupList[0].title;

      this.editFormVisible = true;
    },
    submitForm() {
      // this.editForm.sortBy = this.editForm.sortBy - 0;
      this.editForm.userTypeId = this.utid;

      if (this.editForm.userTypeId == 6 && util.isEmpty(this.editForm.ppid)) {
        this.$message.error("请选择贵宾");
        return;
      }

      if (this.editForm.userTypeId != 6) {
        this.editForm.ppid = "";
      }

      if (this.editForm.status == 3) {
        if (util.isEmpty(this.editForm.idcard)) {
          this.$message.error("请填写证件号码");
          return;
        }
      }

      if (util.isNotEmpty(this.editForm.phone)) {
        if (util.isEmpty(this.editForm.phoneType)) {
          this.$message.error("请选择电话类型");
          return;
        }
      }
      this.$refs.editForm.validate((valid, val) => {
        if (!valid) {
          let msg = "";
          for (let key in val) {
            msg = val[key][0].message;
            break;
          }
          this.$message.error(msg);
        }

        if (this.editForm.qy == "其他") {
          if (util.isEmpty(this.editForm.qyOther)) {
            this.$message.error("请填写付费方式");
            return;
          }
        } else {
          this.editForm.qyOther == "";
        }

        if (valid) {
          this.editForm.hyId = this.editForm.hyIds.join(",");

          this.loading = true;
          let loadingText = "添加中...";
          if (this.editForm.id) {
            loadingText = "修改中...";
          }
          const loadingDiv = util.showLoading(loadingText);
          let func = updateParticipate;
          if (this.editForm.id == 0) {
            func = addParticipate;
          }
          func(this.editForm)
            .then((res) => {
              let title = "修改";
              if (this.editForm.id == 0) {
                title = "添加";
              }
              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.currentPage = 1;
                  this.editFormVisible = false;
                  // if (this.editForm.id > 0) {
                  //   pNum = this.currentPage;
                  // }
                  this.getDataList(this.currentPage);
                });
              }
              this.loading = false;
              loadingDiv.close();
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
    handleDelete(index, row) {
      let loadingDiv;
      this.$prompt("请输入删除原因", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (util.isEmpty(value)) {
            this.$message.error("请填写删除原因");
            this.handleDelete(index, row);
            return;
          } else {
            let loadingDiv = util.showLoading("删除中...");
            deleteParticipate({ pid: row.id, deleteReason: value })
              .then((res) => {
                loadingDiv.close();
                // console.log("===================")
                // console.log(res)
                if (res.code == 0) {
                  this.$alert(res.msg, "删除失败", {
                    dangerouslyUseHTMLString: true,
                    type: "warning",
                    showClose: false,
                  });
                } else if (res.code == 1) {
                  this.$alert(res.msg, "删除成功", {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                    showClose: false,
                  }).then((res) => {
                    this.editFormVisible = false;
                    this.getDataList(this.currentPage);
                  });
                }
              })
              .catch((res) => {
                if (loadingDiv) {
                  loadingDiv.close();
                }
              });
          }
        })
        .catch((res) => {
          // if (loadingDiv){
          //   loadingDiv.close();
          // }
        });
    },

    getDataList() {
      const loadingDiv = util.showLoading();

      getxiaochengxuParticipateList(this.searchForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;

            this.total = data.total;
            this.tableData = data.dataList;
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getDataList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
  
  <style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
  <style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tabs--border-card {
  box-shadow: none;
}
</style>
  