<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="当前嘉宾类型为:">
        <span>{{ actorScore }}</span>
      </el-form-item>

      <el-form-item label="选择嘉宾类型" prop="aftUserType">
        <el-select
          v-model="form.aftUserType"
          clearable
          placeholder="请选择嘉宾类型"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.creClasName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>
    
    <script>
import { genggai } from "@/api/leixing";
import util from "@/common/utils/util.js";

export default {
  name: "TableEdit",
  data() {
    return {
      loading: false,
      options1: [
        {
          id: 1,
          creClasName: "政务贵宾",
        },
        {
          id: 2,
          creClasName: "政务嘉宾",
        },
        {
          id: 3,
          creClasName: "行业贵宾",
        },
        {
          id: 4,
          creClasName: "行业嘉宾",
        },
      ], //状态
      actorScore: "", //当前状态
      actorScorewo: "", //修改后的分数分数
      form: {
        preUserType: "", //变更前
        aftUserType: "", //变更后
        id: "", //当前项的id
      },
      rules: {
        aftUserType: [
          {
            required: true,
            message: "请选择嘉宾类型",
            trigger: "change",
          },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    handleChange() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 300);
    },

    showEdit(row) {
      this.actorScore = row.userTypeName;
      this.form.id = row.id;
      this.form.preUserType = row.userTypeId;
      this.title = "更改嘉宾类型";
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let loadingText = "修改中...";

          const loadingDiv = util.showLoading(loadingText);
          let func = genggai;

          func(this.form)
            .then((res) => {
              let title = "更改嘉宾类型";

              if (res.code == 0) {
                this.$alert(res.msg, title + "失败", {
                  dangerouslyUseHTMLString: true,
                  type: "warning",
                });
                loadingDiv.close();
              } else if (res.code == 1) {
                this.$alert(res.msg, title + "成功", {
                  dangerouslyUseHTMLString: true,
                  type: "success",
                }).then(() => {
                  this.dialogFormVisible = false;
                  this.$emit("fetch-data");
                  loadingDiv.close();
                });
              }
              this.loading = false;
            })
            .catch((res) => {
              this.loading = false;
              loadingDiv.close();
            });
        }
      });
    },
  },
};
</script>
    